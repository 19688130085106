import { combineReducers } from 'redux';

// Import custom components
import sidebarReducer from './reducers/userInterfaceReducers/sidebarReducer';
import formSubmissionStateReducer from './reducers/userInterfaceReducers/formSubmissionStateReducer';
import authReducer from './reducers/userReducers/authReducer';
import userReducer from './reducers/userReducers/userReducer';
import postReducer from './reducers/postReducers/postReducer';
import errorReducer from './reducers/coreReducers/ErrorReducer';
import allTagsReducer from './reducers/tagsReducers/allTagsReducer';
import allPostsReducer from './reducers/postReducers/allPostsReducer';

const reducers = combineReducers({
  sidebarReducer,
  formSubmissionStateReducer,
  authReducer,
  userReducer,
  postReducer,
  errorReducer,
  allTagsReducer,
  allPostsReducer,
});

export default reducers;

// Export Combined Reducers Type
export type RootState = ReturnType<typeof reducers>;
