import React, { FC, ReactElement } from 'react';
import { Paper, Typography, Box, Button, ButtonTypeMap } from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Import custom components
import './cardWidget.css';
import { ChildrenComponent } from '../../../interfaces/childrenComponent';
import { ButtonColors } from '../../../interfaces/ButtonColors';
import ButtonVariants from '../../../interfaces/ButtonVariants';

interface CardWidget extends ChildrenComponent {
  heading?: string;
  button?: boolean;
  buttonText?: string;
  link?: string;
  buttonColor?: ButtonColors;
  buttonVariant?: ButtonVariants;
  style?: Record<string, unknown>;
}

export default function CardWidget(props: CardWidget): ReactElement<FC> {
  // Destructure props
  const {
    heading,
    button = false,
    buttonText = 'Button',
    link = '/',
    buttonColor = 'inherit',
    buttonVariant = 'contained',
    children,
    style,
  } = props;

  return (
    <Paper style={style} variant="outlined" className="cardWidget">
      <Box className="cardHeadingWrapper">
        {heading ? (
          <Typography className="cardWidgetTitle" variant="h3" component="h2">
            {heading}
          </Typography>
        ) : (
          false
        )}
        {button ? (
          <Button
            variant={buttonVariant}
            color={buttonColor}
            disableRipple
            disableElevation
            size="small"
            component={Link}
            to={link}
          >
            {buttonText}
          </Button>
        ) : (
          false
        )}
      </Box>
      {children}
    </Paper>
  );
}

CardWidget.propTypes = {
  heading: PropTypes.string,
  button: PropTypes.bool,
  buttonText: PropTypes.string,
  link: PropTypes.string,
  buttonColor: PropTypes.string,
};
