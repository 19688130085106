import React, { FC, ReactElement } from 'react';
import { Box, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

// Import custom components
import CardAccordion from '../../layouts/CardAccordion/CardAccordion';
import HelperTextFields from '../../utilities/HelperTextFields/HelperTextFields';
import { updateMetaFields } from '../../../store/actions/postFormsActions/metaFieldsUpdatesAction';
import { Post } from '../../../store/reducers/postReducers/postReducer';
import { RootState } from '../../../store/reducers';

export default function FacebookOptions(): ReactElement<FC> {
  // Declare the dispatch method
  const dispatch = useDispatch();

  // Select the post reducer
  const post: Post = useSelector((state: RootState) => state.postReducer);

  return (
    <CardAccordion heading="Facebook Feed Options">
      <Box display="flex" flexDirection="column" sx={{ width: '100%' }}>
        <TextField
          label="Facebook Title"
          placeholder="Facebook Title"
          variant="outlined"
          value={post.metaOptions?.metaValue.facebookTitle || ''}
          onChange={(e) =>
            dispatch(
              updateMetaFields({
                facebookTitle: e.target.value,
              })
            )
          }
          sx={{
            marginBottom: '1.5rem',
          }}
          fullWidth
          helperText={
            <HelperTextFields helperText="The title of your article without any branding such as your site name." />
          }
        />

        <TextField
          label="Description"
          sx={{
            marginBottom: '1.5rem',
          }}
          placeholder="Description"
          variant="outlined"
          fullWidth
          multiline
          value={post.metaOptions?.metaValue.facebookDescription || ''}
          onChange={(e) =>
            dispatch(
              updateMetaFields({
                facebookDescription: e.target.value,
              })
            )
          }
          minRows={4}
          helperText={
            <HelperTextFields helperText="A brief description of the content" />
          }
        />
      </Box>
    </CardAccordion>
  );
}
