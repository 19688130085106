import { AnyAction } from 'redux';
import ActionTypes from '../../actionTypes';

interface ErrorInterface {
  error: boolean;
  message: string;
}

export interface AppErrors {
  featureImageUpload: ErrorInterface;
}

const initialState: AppErrors = {
  featureImageUpload: {
    error: false,
    message:
      'Could Not upload the image. Only supported formats .jpg, .jpeg, .png',
  },
};

const errorReducer = (state: AppErrors = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionTypes.featuredImageUploadError:
      return {
        ...state,
        featureImageUpload: { ...state.featureImageUpload, error: true },
      };

    case ActionTypes.clearAllErrors:
      return { ...initialState };

    default:
      return state;
  }
};

export default errorReducer;
