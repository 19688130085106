import React, { FC, ReactElement } from 'react';
import { Box, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

// Import custom components
import CardAccordion from '../../layouts/CardAccordion/CardAccordion';
import HelperTextFields from '../../utilities/HelperTextFields/HelperTextFields';

import SerpDisplay from '../../utilities/SerpDisplay/SerpDisplay';
import { updateMetaFields } from '../../../store/actions/postFormsActions/metaFieldsUpdatesAction';
import { Post } from '../../../store/reducers/postReducers/postReducer';
import { RootState } from '../../../store/reducers';

export default function SearchEngineOptions(): ReactElement<FC> {
  // Declare the dispath method
  const dispatch = useDispatch();

  // Select the post
  const post: Post = useSelector((state: RootState) => state.postReducer);

  return (
    <CardAccordion heading="Search Engine Options">
      <Box display="flex" flexDirection="column" sx={{ width: '100%' }}>
        <TextField
          label="Meta Title"
          placeholder="Meta Title"
          variant="outlined"
          value={post.metaOptions?.metaValue.metaTitle || ''}
          onChange={(e) =>
            dispatch(
              updateMetaFields({
                metaTitle: e.target.value,
              })
            )
          }
          sx={{
            marginBottom: '1.5rem',
          }}
          fullWidth
          helperText={
            <HelperTextFields helperText="Google typically displays the first 50–60 characters of a title tag." />
          }
        />

        <TextField
          label="Meta Description"
          sx={{
            marginBottom: '1.5rem',
          }}
          placeholder="Meta Description"
          variant="outlined"
          fullWidth
          multiline
          value={post.metaOptions?.metaValue.metaDescription || ''}
          onChange={(e) =>
            dispatch(
              updateMetaFields({
                metaDescription: e.target.value,
              })
            )
          }
          minRows={4}
          helperText={
            <HelperTextFields helperText="Meta descriptions can be any length, but Google generally truncates snippets to ~155–160 characters" />
          }
        />

        <TextField
          label="Canonical URL"
          placeholder="Canonical URL"
          variant="outlined"
          value={post.metaOptions?.metaValue.canonicalUrl || ''}
          onChange={(e) =>
            dispatch(
              updateMetaFields({
                canonicalUrl: e.target.value,
              })
            )
          }
          sx={{
            marginBottom: '1.5rem',
          }}
          fullWidth
          helperText={
            <HelperTextFields helperText="A canonical URL is the URL of the page that Google thinks is most representative from a set of duplicate pages on your site" />
          }
        />
      </Box>
    </CardAccordion>
  );
}
