import { put, takeEvery } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { AxiosResponse } from 'axios';

// Import custom compoenents
import ActionTypes from '../../actionTypes';
import axiosBaseInstance from '../../../config/axios/axiosBaseInstance';
import getCurrentUser from '../../../config/getCurrentUser/getCurrentUser';
import { User } from '../../reducers/userReducers/userReducer';
import {
  formSubmissionStarted,
  formSubmissionEnded,
} from '../../actions/genericFormActions/genericFormActions';
import { updatePostReducerWithServerPost } from '../../actions/postFormsActions/savingPostActions';
import convertServerResponseToPost from '../../../helpers/convertServerResponseToPost';

export function* fetchPost(action: AnyAction) {
  const user: User = yield getCurrentUser();
  yield put(formSubmissionStarted());
  try {
    const response: AxiosResponse = yield axiosBaseInstance.get(
      `/posts?id=${action.id}`
    );
    if (response.status === 200) {
      yield put(
        updatePostReducerWithServerPost(
          convertServerResponseToPost(response.data.items[0])
        )
      );
      yield put(formSubmissionEnded());
    } else {
      yield put(formSubmissionEnded());
    }
  } catch (errors) {
    yield put(formSubmissionEnded());
  }
}

export function* fetchSinglePostFromServer() {
  yield takeEvery(ActionTypes.fetchPostFromServer, fetchPost);
}
