import { AnyAction } from 'redux';

// Import custom components
import ActionTypes from '../../actionTypes';
import { AssignTagsToPost } from '../../reducers/postReducers/postReducer';

export function changePostStatus(status: string): AnyAction {
  return {
    type: ActionTypes.changePostStatus,
    status,
  };
}

export function updateFeaturedImageUrl(featuredImage: string): AnyAction {
  return {
    type: ActionTypes.updateFeaturedImageUrl,
    featuredImage,
  };
}

export function featuredImageUploadError(): AnyAction {
  return {
    type: ActionTypes.featuredImageUploadError,
  };
}

export function updateTagsToPostReducer(tags: AssignTagsToPost[]): AnyAction {
  return {
    type: ActionTypes.updateTagsToPostReducer,
    tags,
  };
}

export function updateSlugToPostReducer(slug: string): AnyAction {
  return {
    type: ActionTypes.updateSlugToPostReducer,
    slug,
  };
}

export function removeFeaturedImageFromReducer(): AnyAction {
  return {
    type: ActionTypes.removeFeaturedImageFromReducer,
  };
}
