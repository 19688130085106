import React, { FC, ReactElement } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

// User created component imports
import lightTheme from '../../config/themes/lightTheme';
import { ChildrenComponent } from '../../interfaces/childrenComponent';

export default function CustomThemeProvider(
  props: ChildrenComponent
): ReactElement<FC> {
  // Destructure props
  const { children } = props;

  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
