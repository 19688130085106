import { AnyAction } from 'redux';

import ActionTypes from '../../actionTypes';

export function formSubmissionStarted(): AnyAction {
  return {
    type: ActionTypes.formSubmissionStarted,
  };
}

export function formSubmissionEnded(): AnyAction {
  return {
    type: ActionTypes.formSubmissionEnded,
  };
}

export function clearAllErrors(): AnyAction {
  return {
    type: ActionTypes.clearAllErrors,
  };
}
