import { put, takeEvery } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { AxiosResponse } from 'axios';

// Import custom compoenents
import ActionTypes from '../../actionTypes';
import axiosBaseInstance from '../../../config/axios/axiosBaseInstance';
import getCurrentUser from '../../../config/getCurrentUser/getCurrentUser';
import { User } from '../../reducers/userReducers/userReducer';
import {
  formSubmissionStarted,
  formSubmissionEnded,
} from '../../actions/genericFormActions/genericFormActions';
import { initiateGetAllTags } from '../../actions/tagFormActions/tagFormActions';

export function* createTag(action: AnyAction) {
  const user: User = yield getCurrentUser();
  yield put(formSubmissionStarted());
  try {
    const response: AxiosResponse = yield axiosBaseInstance.post(
      '/tags/create',
      {
        name: action.tag.name,
        slug: action.tag.slug,
        description: action.tag.description ? action.tag.description : null,
        schema: action.tag.schema ? action.tag.schema : null,
        featuredImage: action.tag.featuredImage
          ? action.tag.featuredImage
          : null,
      },
      {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      }
    );
    if (response.status === 201) {
      yield put(initiateGetAllTags());
      yield put(formSubmissionEnded());
    } else {
      yield put(formSubmissionEnded());
    }
  } catch (errors) {
    yield put(formSubmissionEnded());
  }
}

export function* createNewTagSaga() {
  yield takeEvery(ActionTypes.initiationTagCreation, createTag);
}
