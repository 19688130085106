import { Post } from '../store/reducers/postReducers/postReducer';

export default function preparePostToSaveOnServer(post: Post) {
  return {
    ...post,
    title: post.title,
    content: post.content,
    metaOptions: {
      ...post.metaOptions,
      metaValue: JSON.stringify(post.metaOptions?.metaValue),
    },
  };
}
