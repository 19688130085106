import { AnyAction } from 'redux';

// Import custom components
import ActionTypes from '../../actionTypes';

export function updateSchemaToPostReducer(
  schema: string | undefined
): AnyAction {
  return {
    type: ActionTypes.updateSchemaToPostReducer,
    schema,
  };
}
