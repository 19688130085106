import React, { FC, ReactElement } from 'react';
import { Box, Stack, Pagination } from '@mui/material';

// Import Custom component
import CardWidget from '../../layouts/CardWidget/CardWidget';
import './recentPosts.css';
import BasicPostRow from './BasicPostRow';

export default function RecentPosts(): ReactElement<FC> {
  return (
    <CardWidget heading="Recent Posts">
      <BasicPostRow
        title="This is a new Post"
        editUrl="#"
        publicUrl="#"
        status="draft"
      />
      <BasicPostRow
        title="This is a new Post"
        editUrl="#"
        publicUrl="#"
        status="draft"
      />
      <Box display="flex" justifyContent="end">
        <Pagination shape="rounded" count={10} color="primary" />
      </Box>
    </CardWidget>
  );
}
