import { fork } from 'redux-saga/effects';

// Import Sagas
import { initiateImgaeFileUpload } from './sagas/fileUploadSagas/imageUploadSaga';
import { userLoginSaga } from './sagas/userSagas/UserLoginSaga';
import { refreshTokenSaga } from './sagas/userSagas/RefreshTokenSaga';
import { createNewTagSaga } from './sagas/tagRelatedSagas/createTagSaga';
import { getAllTagsSaga } from './sagas/tagRelatedSagas/getAllTagsSaga';
import { createNewPostSaga } from './sagas/postRelatedSagas/createPostSaga';
import { fetchSinglePostFromServer } from './sagas/postRelatedSagas/fetchSinglePostSaga';
import { updatePostOnServerSaga } from './sagas/postRelatedSagas/updatePostOnServerSaga';
import { fetchAllPostsSaga } from './sagas/postRelatedSagas/fetchAllPostsSaga';

export default function* rootSaga() {
  yield fork(initiateImgaeFileUpload);
  yield fork(userLoginSaga);
  yield fork(refreshTokenSaga);
  yield fork(createNewTagSaga);
  yield fork(getAllTagsSaga);
  yield fork(createNewPostSaga);
  yield fork(fetchSinglePostFromServer);
  yield fork(updatePostOnServerSaga);
  yield fork(fetchAllPostsSaga);
}
