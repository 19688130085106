import React, { FC, ReactElement } from 'react';
import { Grid } from '@mui/material';
import MetaOptionsSwitch from '../MetaOptionsSwitch/MetaOptionsSwitch';
import CardAccordion from '../../layouts/CardAccordion/CardAccordion';

export default function PostMetaOptions(): ReactElement<FC> {
  return (
    <CardAccordion heading="Post Options">
      <Grid spacing={3} container>
        <Grid item lg={6}>
          <MetaOptionsSwitch
            label="Table Of Contents"
            onChange={(e) => console.log(e.target.checked)}
          />
        </Grid>
        <Grid item lg={6}>
          <MetaOptionsSwitch
            label="Display Author Details"
            onChange={(e) => console.log(e.target.checked)}
          />
        </Grid>
      </Grid>
    </CardAccordion>
  );
}
