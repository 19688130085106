const settings = {
  user: {
    defaultRedirect: '',
    redirectWhenAuth: '',
  },
  app: {
    apiEndpoint: 'https://api.wikimonday.com/', // http://localhost:3200/ , https://api.wikimonday.com/,
    timeToResetErrors: 10000,
    tokenRefreshTime: 600000,
  },
  aws: {
    cloudFrontUrl: '//d1873a1z46rk00.cloudfront.net',
  },
};

export default settings;
