import React, { FC, ReactElement } from 'react';
import { Routes, Route } from 'react-router-dom';

// Import Custom Components
import routePaths from './routePaths';
import LoginPage from '../../components/pages/LoginPage/LoginPage';
import Dashboard from '../../components/pages/Dashboard/Dashboard';
import AddNewPost from '../../components/pages/AddNewPost/AddNewPost';
import ProtectedRoutes from '../../components/utilities/ProtectedRoutes/ProtectedRoutes';
import AddTags from '../../components/pages/AddTags/AddTags';
import AllPosts from '../../components/pages/AllPosts/AllPosts';

export default function ZootashRouter(): ReactElement<FC> {
  return (
    <Routes>
      <Route element={<ProtectedRoutes />}>
        <Route path={routePaths.root} element={<Dashboard />} />
        <Route path={`${routePaths.allPosts}`} element={<AllPosts />} />
        <Route path={`${routePaths.addNewPost}`} element={<AddNewPost />} />
        <Route path={`${routePaths.editPost}/:id`} element={<AddNewPost />} />
        <Route path={`${routePaths.addTags}`} element={<AddTags />} />
      </Route>

      <Route path={routePaths.login} element={<LoginPage />} />
    </Routes>
  );
}
