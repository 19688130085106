import { AnyAction } from 'redux';
import { cloneDeep } from 'lodash';

// Import custom components
import { UserRoles } from '../../../enums/UserRoles';
import ActionTypes from '../../actionTypes';

export interface User {
  id: string | undefined;
  email: string | undefined;
  firstName: string | undefined;
  lastName?: string;
  profileImage?: null | string;
  apiKey?: string;
  emailVerified?: boolean;
  emailVerificationDate?: null | string;
  createDate?: string;
  updateDate?: string;
  accessToken?: string;
  isOwner?: boolean;
  role?: {
    id?: string;
    name?: UserRoles;
  };
}

const initialState: User = {
  id: undefined,
  email: undefined,
  firstName: undefined,
};

const updateUser = (state: User, action: AnyAction) => {
  return cloneDeep(action.user);
};

const updateAccessToken = (state: User, action: AnyAction) => {
  return {
    ...state,
    accessToken: action.accessToken,
  };
};

const userReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionTypes.updateUserReducer:
      return updateUser(state, action);

    case ActionTypes.updateRefreshedToken:
      return updateAccessToken(state, action);

    case ActionTypes.resetUserState:
      return { ...initialState };

    default:
      return state;
  }
};

export default userReducer;
