import React, { FC, ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

// Import custom components
import './sidebarNavigation.css';
import SidebarButton from './SidebarButton';
import SidebarAccordion from './SidebarAccordion';
import { RootState } from '../../../store/reducers';

export default function SidebarNavigation(): ReactElement<FC> {
  // Get the SidebarState from redux
  const sidebarState = useSelector((state: RootState) => state.sidebarReducer);

  // Set the default state for the sidebar
  const [sidebarObject] = useState(sidebarState);

  return (
    <div style={{ width: '100%' }}>
      {Array.isArray(sidebarObject)
        ? sidebarObject.map((object) =>
            object.type === 'button' ? (
              <SidebarButton
                key={uuidv4()}
                link={object.link}
                heading={object.heading}
                nested={false}
                hasIcon={object.hasIcon}
                icon={<object.icon />}
                type={object.type}
              />
            ) : (
              <SidebarAccordion
                key={uuidv4()}
                hasIcon
                icon={<object.icon />}
                nested={false}
                link={object.link}
                type={object.type}
                heading={object.heading}
                links={object.links}
              />
            )
          )
        : 'Pass a valid object to sidebar navigation'}
    </div>
  );
}
