import { Action } from 'redux';
import ActionTypes from '../../actionTypes';

export interface FormSubmissionState {
  inProcess: boolean;
}

const initialState: FormSubmissionState = {
  inProcess: false,
};

const formSubmissionStateReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.formSubmissionStarted:
      return { inProcess: true };

    case ActionTypes.formSubmissionEnded:
      return { inProcess: false };

    default:
      return state;
  }
};

export default formSubmissionStateReducer;
