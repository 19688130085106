import React from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';

// Import Custom Components
import Tiptap from '../TipTapEditor/TipTapEditor';
import { updateContentToPostReducer } from '../../../store/actions/postFormsActions/tipTapActions/tipTapActions';
import { Post } from '../../../store/reducers/postReducers/postReducer';
import { RootState } from '../../../store/reducers';
import CardAccordion from '../../layouts/CardAccordion/CardAccordion';

export default function PostEditor() {
  // Select the post reducer
  const post: Post = useSelector((state: RootState) => state.postReducer);

  return (
    <CardAccordion defaultExpanded heading="Post Content">
      <Grid spacing={3} container>
        <Grid item lg={12}>
          <Tiptap
            tiptapInitialContent={post.contentOnServer}
            tiptapContentDispatch={updateContentToPostReducer}
          />
        </Grid>
      </Grid>
    </CardAccordion>
  );
}
