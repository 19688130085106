import React, { FC, ReactElement } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import PropTypes from 'prop-types';

// Import custom components
import './sidebarNavigation.css';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import { SidebarObjectInterface } from '../../../interfaces/sidebarLinksInterfaces';

export default function SidebarButton(
  props: SidebarObjectInterface
): ReactElement<FC> {
  // Destructure props
  const {
    nested = false,
    heading = 'button',
    link = '#',
    hasIcon = false,
    icon = <GridViewOutlinedIcon />,
  } = props;

  // Get the current route
  const location = useLocation();

  return (
    <Box display="flex" marginBottom={2} paddingX={1}>
      <Button
        component={Link}
        sx={{
          color: 'text.primary',
          fontSize: (theme) => theme.typography.body1.fontSize,
          fontWeight: 400,
          marginLeft: nested ? '1rem' : 0,
        }}
        className={`sidebarButton ${
          location.pathname === link ? 'active' : false
        }`}
        to={link}
        disableRipple
      >
        {hasIcon ? (
          <Box
            sx={{
              marginRight: '.5rem',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            {icon}
          </Box>
        ) : (
          false
        )}
        {heading}
      </Button>
    </Box>
  );
}

SidebarButton.propTypes = {
  nested: PropTypes.bool,
  heading: PropTypes.string,
  link: PropTypes.string,
  hasIcon: PropTypes.bool,
  icon: PropTypes.node,
};
