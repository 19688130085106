import React from 'react';
import { Paper, Divider, Box } from '@mui/material';
import PropTypes from 'prop-types';
import SidebarNavigation from '../../utilities/SidebarNavigation/SidebarNavigation';

// Import Custom Components
import './sidebar.css';

export default function Sidebar() {
  return (
    <Paper variant="outlined" className="sidebarWrapper">
      <Box className="sidebarLogoWrapper">
        <img alt="dashboard logo" src="/assets/images/logo.png" />
      </Box>
      <Divider variant="fullWidth" />
      <Box className="sidebarLogoWrapper">
        <SidebarNavigation />
      </Box>
    </Paper>
  );
}

// Declare proptypes
Sidebar.propTypes = {};
