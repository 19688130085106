import React, { FC, ReactElement } from 'react';
import { Paper, Chip, Avatar } from '@mui/material';

// Import custom components
import './topNavbar.css';
import TopbarProfileButton from '../../utilities/TopbarProfileButton/TopbarProfileButton';

export default function TopNavbar(): ReactElement<FC> {
  return (
    <Paper className="topnavbarWrap" variant="outlined" square>
      <TopbarProfileButton name="Manik" />
    </Paper>
  );
}
