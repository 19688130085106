import { AnyAction } from 'redux';
import { cloneDeep } from 'lodash';
import { HTMLContent, JSONContent } from '@tiptap/react';

// Import custom components
import { PostStatus } from '../../../enums/PostStatus';
// @ts-ignore
import { PostType } from '../../../enums/PostType';
import ActionTypes from '../../actionTypes';
import { consoleLogDetails } from '../../../helpers/consoleLogDetails';

export interface AssignTagsToPost {
  name: string;
}

export interface MetaFieldsInterface {
  [key: string]: unknown;
}

export interface MetaOptionsInterface {
  metaValue: MetaFieldsInterface;
}

export interface postUser {
  firstName: string;
  id: string;
  lastName: string;
  profileImage: string | null;
}

export interface Post {
  id: string | undefined;
  title: string | undefined;
  content: HTMLContent;
  contentOnServer: HTMLContent;
  localContent?: JSONContent;
  schema: string;
  postType: PostType;
  slug: string | undefined;
  featuredImage?: string | undefined;
  status: PostStatus;
  previewUrl?: string;
  wordCount?: number;
  minutesToRead?: number;
  publishOn?: string;
  createDate?: string;
  updateDate?: string;
  tags?: AssignTagsToPost[] | undefined;
  metaOptions?: MetaOptionsInterface;
  user?: postUser;
}

const initialState: Post = {
  id: undefined,
  title: undefined,
  postType: PostType.POST,
  slug: undefined,
  status: PostStatus.DRAFT,
  content: '',
  contentOnServer: '',
  localContent: {
    type: 'doc',
    content: [
      {
        type: 'heading',
        attrs: {
          textAlign: 'left',
          level: 1,
        },
      },
    ],
  },
  tags: [],
  schema: '{}',
  metaOptions: {
    metaValue: {
      displayAuthor: true,
      displayTableOfContents: true,
    },
  },
};

function updateTags(state: Post, action: AnyAction) {
  const newState = { ...state };
  const newTags = cloneDeep(action.tags);
  return {
    ...newState,
    tags: newTags,
  };
}

function clearStorageAndSetState() {
  return cloneDeep(initialState);
}

const postReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionTypes.resetReducerToInitialState:
      return clearStorageAndSetState();
    case ActionTypes.changePostStatus:
      return { ...state, status: action.status };
    case ActionTypes.updateTagsToPostReducer:
      return updateTags(state, action);
    case ActionTypes.updateFeaturedImageUrl:
      return { ...state, featuredImage: action.featuredImage };
    case ActionTypes.updateContentToPostReducer:
      return {
        ...state,
        content: action.content,
        localContent: action.localContent,
        title: action.title,
      };
    case ActionTypes.updateSchemaToPostReducer:
      return { ...state, schema: action.schema };
    case ActionTypes.updateMetaFieldsToPostReducer:
      return {
        ...state,
        metaOptions: {
          ...cloneDeep(state.metaOptions),
          metaValue: { ...state.metaOptions?.metaValue, ...action.payload },
        },
      };
    case ActionTypes.updateSlugToPostReducer:
      return {
        ...state,
        slug: action.slug,
      };
    case ActionTypes.removeFeaturedImageFromReducer:
      return {
        ...state,
        featuredImage: undefined,
      };
    case ActionTypes.updatePostReducerWithServerPost:
      return {
        ...cloneDeep(action.post),
      };
    default:
      return state;
  }
};

export default postReducer;
