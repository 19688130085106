/* eslint react/prop-types: 0 */
import React, { useEffect, useState, useCallback } from 'react';
import {
  useEditor,
  EditorContent,
  BubbleMenu,
  FloatingMenu,
  ReactNodeViewRenderer,
  HTMLContent,
  JSONContent,
} from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Highlight from '@tiptap/extension-highlight';
import Typography from '@tiptap/extension-typography';
import TextAlign from '@tiptap/extension-text-align';
import Document from '@tiptap/extension-document';
import Placeholder from '@tiptap/extension-placeholder';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import Image from '@tiptap/extension-image';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CodeIcon from '@mui/icons-material/Code';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';

// load all highlight.js languages
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { lowlight } from 'lowlight';

// Import custom components
import './tipTapEditor.scss';
import CodeBlockComponent from './CodeBlockComponent';
import extractTitleFromContent from '../../../helpers/extractTitleFromContent';

const CustomDocument = Document.extend({
  content: 'heading block*',
});

export interface TipTapInterface {
  tiptapContentDispatch: (
    content: HTMLContent,
    title: string,
    localContent: JSONContent
  ) => AnyAction;
  tiptapInitialContent: HTMLContent;
}

export default function Tiptap(props: TipTapInterface) {
  /*
   * ===========
   * Initializers
   * ===========
   * */
  // Destructure props
  const { tiptapContentDispatch, tiptapInitialContent } = props;
  // Declare the dispatch Function
  const dispatch = useDispatch();

  /*
   * ===========
   * States
   * ===========
   * */

  /*
   * ===========
   * Editor Instantiation
   * ===========
   * */
  const editor = useEditor({
    extensions: [
      CustomDocument,
      StarterKit.configure({
        document: false,
        codeBlock: false,
      }),
      Highlight,
      Typography,
      Image,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Placeholder.configure({
        placeholder: ({ node }) => {
          if (node.type.name === 'heading') {
            return 'What’s the title?';
          }
          return 'Write some nice and engaging :)';
        },
      }),
      CodeBlockLowlight.extend({
        addNodeView() {
          return ReactNodeViewRenderer(CodeBlockComponent);
        },
      }).configure({ lowlight }),
    ],
    // eslint-disable-next-line @typescript-eslint/no-shadow
    onUpdate: ({ editor }) => {
      dispatch(
        tiptapContentDispatch(
          editor.getHTML(),
          extractTitleFromContent(editor.getJSON()),
          editor.getJSON()
        )
      );
    },
    content: '',
  });

  /*
   * Tiptap Method Calls
   * */
  // Function to upload image
  const addImage = () => {
    const url = window.prompt('URL');
    if (url && editor) {
      editor.chain().focus().setImage({ src: url }).run();
    }
  };

  const changeContent = useCallback(
    (content: HTMLContent) => {
      editor?.commands.setContent(content);
    },
    [tiptapInitialContent]
  );

  /*
   * ===========
   * SideEffects
   * ===========
   * */
  useEffect(() => {
    changeContent(tiptapInitialContent);
  }, [tiptapInitialContent]);

  return (
    <>
      {editor && (
        <BubbleMenu
          className="bubble-menu"
          tippyOptions={{ duration: 100 }}
          editor={editor}
        >
          <button
            type="button"
            onClick={() => editor.chain().focus().toggleBold().run()}
            className={editor.isActive('bold') ? 'is-active' : ''}
          >
            <FormatBoldIcon />
          </button>
          <button
            type="button"
            onClick={() => editor.chain().focus().toggleItalic().run()}
            className={editor.isActive('italic') ? 'is-active' : ''}
          >
            <FormatItalicIcon />
          </button>
          <button
            type="button"
            onClick={() => editor.chain().focus().toggleStrike().run()}
            className={editor.isActive('strike') ? 'is-active' : ''}
          >
            <FormatStrikethroughIcon />
          </button>
          <button
            type="button"
            onClick={() => editor.chain().focus().setParagraph().run()}
            className={editor.isActive('paragraph') ? 'is-active' : ''}
          >
            <ViewHeadlineIcon />
          </button>
          <button
            type="button"
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 2 }).run()
            }
            className={
              editor.isActive('heading', { level: 2 }) ? 'is-active' : ''
            }
          >
            H2
          </button>
          <button
            type="button"
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 3 }).run()
            }
            className={
              editor.isActive('heading', { level: 3 }) ? 'is-active' : ''
            }
          >
            H3
          </button>
          <button
            type="button"
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            className={editor.isActive('bulletList') ? 'is-active' : ''}
          >
            <FormatListBulletedIcon />
          </button>
        </BubbleMenu>
      )}

      {editor && (
        <FloatingMenu
          className="floating-menu"
          tippyOptions={{ duration: 100 }}
          editor={editor}
        >
          <button
            type="button"
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 2 }).run()
            }
            className={
              editor.isActive('heading', { level: 2 }) ? 'is-active' : ''
            }
          >
            H2
          </button>
          <button
            type="button"
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 3 }).run()
            }
            className={
              editor.isActive('heading', { level: 3 }) ? 'is-active' : ''
            }
          >
            H3
          </button>

          <button
            type="button"
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            className={editor.isActive('bulletList') ? 'is-active' : ''}
          >
            <FormatListBulletedIcon />
          </button>
          <button
            type="button"
            onClick={() => editor.chain().focus().toggleCodeBlock().run()}
            className={editor.isActive('codeBlock') ? 'is-active' : ''}
          >
            <CodeIcon />
          </button>

          <button type="button" onClick={addImage}>
            <ImageOutlinedIcon />
          </button>
        </FloatingMenu>
      )}
      <EditorContent editor={editor} />
    </>
  );
}
