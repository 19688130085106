import React, { FC, ReactElement } from 'react';
import { Box, Typography, IconButton, Chip } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';

// Import custom Components
import './recentPosts.css';
import renderCorrectColorForPostStatus from '../../../helpers/renderCorrectColorForPostStatus';

interface BasicPostRow {
  title: string;
  status: string;
  publicUrl: string;
  editUrl: string;
}

export default function BasicPostRow({
  title,
  status,
  publicUrl,
  editUrl,
}: BasicPostRow): ReactElement<FC> {
  return (
    <Box className="basicPostRow">
      <Box display="flex" flex={4}>
        <Typography> {title} </Typography>
      </Box>
      <Box display="flex" flex={1} justifyContent="flex-end">
        <Chip label="primary" color={renderCorrectColorForPostStatus(status)} />
      </Box>
      <Box display="flex" flex={1} justifyContent="flex-end">
        <IconButton
          color="default"
          aria-label="Open Actions Panel"
          component="span"
        >
          <MoreVertIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

BasicPostRow.propTypes = {
  title: PropTypes.string,
  status: PropTypes.string,
  publicUrl: PropTypes.string,
  editUrl: PropTypes.string,
};
