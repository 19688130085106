import React, { ReactElement, FC } from 'react';
import { Grid, Paper } from '@mui/material';

// Import Custom Components
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout';
import RecentPosts from '../../posts/RecentPosts/RecentPosts';

export default function Dashboard(): ReactElement<FC> {
  return (
    <DashboardLayout>
      <Grid item lg={8}>
        <RecentPosts />
      </Grid>

      <Grid item lg={4}>
        <Paper>test</Paper>
      </Grid>
    </DashboardLayout>
  );
}
