import { select } from 'redux-saga/effects';

// Import Custom Components
import { RootState } from '../../store/reducers';

const getUser: (state: RootState) => any = (state: RootState) =>
  state.userReducer;

export default function getCurrentUser() {
  return select(getUser);
}
