import { SearchMultiSelectInterface } from './SearchMultiSelectInterface';
import { AllTagsInterface } from '../../../store/reducers/tagsReducers/allTagsReducer';

export default function convertTagsToMultiselectFormat(
  tags: AllTagsInterface
): SearchMultiSelectInterface[] {
  const tagItems = tags.items;
  let newTagItems: SearchMultiSelectInterface[] = [];

  if (tagItems && Array.isArray(tagItems)) {
    newTagItems = tagItems.map((each) => {
      return {
        value: each.name,
        label: each.name,
      };
    });
  }
  return newTagItems;
}
