import { createTheme } from '@mui/material';

const lightTheme = createTheme({
  palette: {
    primary: {
      main: '#7E22CE',
      light: '#a855f7',
      dark: '#581c87',
    },
    secondary: {
      main: '#A21CAF',
      light: '#D946EF',
      dark: '#701A75',
    },
    text: {
      primary: 'rgba(0,0,0,0.8)',
    },
    divider: 'rgba(0,0,0,0.12)',
  },
  typography: {
    h1: {
      fontSize: '1.4rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '1.25rem',
      fontWeight: 700,
    },
    h3: {
      fontSize: '1.125rem',
      fontWeight: 700,
    },
    h4: {
      fontSize: '1.125rem',
      fontWeight: 500,
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 700,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 500,
    },
  },
  spacing: 8,
  shape: {
    borderRadius: 8,
  },
});

export default lightTheme;
