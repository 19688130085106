import React, { ReactElement, FC, useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

// Import Custom Components
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout';
import CardWidget from '../../layouts/CardWidget/CardWidget';
import AddTagForm from '../../forms/AddTagForm/AddTagForm';
import SimpleActionList from '../../utilities/SimpleActionList/SimpleActionList';
import { RootState } from '../../../store/reducers';
import { initiateGetAllTags } from '../../../store/actions/tagFormActions/tagFormActions';
import {
  AllTagsInterface,
  TagInterface,
} from '../../../store/reducers/tagsReducers/allTagsReducer';

export default function AddTags(): ReactElement<FC> {
  // create the dispatch method
  const dispatch = useDispatch();

  // Select all tags Reducer
  const alltags = useSelector((state: RootState) => state.allTagsReducer);

  // Fire useEffect to get all the tags from the API
  useEffect(() => {
    dispatch(initiateGetAllTags());
  }, []);

  return (
    <DashboardLayout>
      <Grid item lg={7}>
        <CardWidget heading="Add New Tag">
          <AddTagForm />
        </CardWidget>
      </Grid>

      <Grid item lg={5}>
        <CardWidget heading="Existing Tags">
          {Array.isArray(alltags.items) && alltags.items.length > 0 ? (
            alltags.items.map((tag: TagInterface) => {
              return <SimpleActionList key={uuidv4()} title={tag.name} />;
            })
          ) : (
            <Typography color="error">
              No Tags have been created. Please create some tags
            </Typography>
          )}
        </CardWidget>
      </Grid>
    </DashboardLayout>
  );
}
