import React, { FC, ReactElement, useCallback, useEffect } from 'react';
import { useDispatch, useSelector, Selector } from 'react-redux';

// Import custom components
import { ChildrenComponent } from '../../../interfaces/childrenComponent';
import { initiateTokenRefresh } from '../../../store/actions/userActions/userActions';
import { RootState } from '../../../store/reducers';
import settings from '../../../config/settings';

export default function RefreshTokens(
  props: ChildrenComponent
): ReactElement<FC> {
  // Detructure props
  const { children } = props;

  // Select the user reducer from the Root
  const user = useSelector((state: RootState) => state.userReducer);

  // Declare the dispatch function
  const dispatch = useDispatch();

  // Function runs at every interval
  const getToken = useCallback(() => {
    if (user.accessToken) {
      dispatch(initiateTokenRefresh(user.accessToken));
    } else {
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(
      () => getToken(),
      settings.app.tokenRefreshTime
    );
    return () => clearInterval(interval);
  }, [getToken]);

  return <> {children} </>;
}
