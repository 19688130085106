import { AnyAction } from 'redux';

// Import custom components
import ActionTypes from '../../actionTypes';
import { Post } from '../../reducers/postReducers/postReducer';

export function createPostOnServer(post: Post): AnyAction {
  return {
    type: ActionTypes.createPostOnServer,
    post,
  };
}

export function updatePostOnServer(post: Post): AnyAction {
  return {
    type: ActionTypes.updatePostOnServer,
    post,
  };
}

export function updatePostReducerWithServerPost(post: Post): AnyAction {
  return {
    type: ActionTypes.updatePostReducerWithServerPost,
    post,
  };
}

export function fetchPostFromServer(id: string): AnyAction {
  return {
    type: ActionTypes.fetchPostFromServer,
    id,
  };
}
