import React, { FC, ReactElement } from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

interface CustomHelperTextForUrl {
  link: string;
}

export default function CustomHelperTextForUrl(
  props: CustomHelperTextForUrl
): ReactElement<FC> {
  // Destructure props
  const { link } = props;

  return (
    <Box
      component="span"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
    >
      <Typography component="small" variant="caption">
        url-of-the-post
      </Typography>
      <Link style={{ display: 'flex', alignItems: 'flex-start' }} to={link}>
        <Typography
          color="primary"
          display="inline-flex"
          component="small"
          variant="caption"
        >
          view post
          <OpenInNewIcon
            style={{ fontSize: '13px', marginTop: '4px', marginLeft: '4px' }}
          />
        </Typography>
      </Link>
    </Box>
  );
}
