import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import StyleOutlinedIcon from '@mui/icons-material/StyleOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';

// Import Custom Components
import routePaths from '../../bootstrap/Router/routePaths';
import { SidebarObjectInterface } from '../../interfaces/sidebarLinksInterfaces';

const sidebarObject: SidebarObjectInterface[] = [
  {
    type: 'button',
    heading: 'Dashboard',
    link: routePaths.root,
    hasIcon: true,
    icon: GridViewOutlinedIcon,
  },
  {
    type: 'accordion',
    heading: 'Posts',
    link: '#',
    hasIcon: true,
    icon: ArticleOutlinedIcon,
    links: [
      {
        type: 'button',
        heading: 'All Posts',
        link: routePaths.allPosts,
        hasIcon: false,
        icon: AddBoxOutlinedIcon,
      },
      {
        type: 'button',
        heading: 'Add New',
        link: routePaths.addNewPost,
        hasIcon: false,
        icon: AddBoxOutlinedIcon,
      },
    ],
  },
  {
    type: 'accordion',
    heading: 'Tags',
    link: '#',
    hasIcon: true,
    icon: StyleOutlinedIcon,
    links: [
      {
        type: 'button',
        heading: 'Add New Tag',
        link: routePaths.addTags,
        hasIcon: false,
        icon: AddBoxOutlinedIcon,
      },
    ],
  },
];

export default sidebarObject;
