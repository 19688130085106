import React, { FC, ReactElement } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

// Import Custom Components// Om AMb Angarkaye namah: - managal Beej mantra
// Om Shom Shomaya Namah - Chnadra Beej Mantra (Shiv Chalisa)
import SidebarButton from './SidebarButton';
import { SidebarObjectInterface } from '../../../interfaces/sidebarLinksInterfaces';

export default function SidebarAccordion(
  props: SidebarObjectInterface
): ReactElement<FC> {
  // destructure props
  const {
    heading = 'Accordion',
    icon = <ArticleOutlinedIcon />,
    links,
  } = props;

  return (
    <Accordion className="sidebarAccordion" square>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box component="span" className="accordionIcon">
          {icon}
        </Box>
        <Typography>{heading}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {Array.isArray(links)
          ? links.map((link) => (
              <SidebarButton
                key={uuidv4()}
                heading={link.heading}
                link={link.link}
                nested
                hasIcon={link.hasIcon}
                type={link.type}
              />
            ))
          : 'Nested Links not provided for this heading'}
      </AccordionDetails>
    </Accordion>
  );
}

// Declare proptypes
SidebarAccordion.proptype = {
  heading: PropTypes.string,
  icon: PropTypes.node,
  links: PropTypes.arrayOf(Object),
};
