import React, { FC, ReactElement } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import { TextField, Typography } from '@mui/material';

// User created component imports
import { ChildrenComponent } from '../../../interfaces/childrenComponent';

// Define styling
const mainImage = {
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  repeat: 'no-repeat',
  minHeight: '100vh',
};

const mainTitle = {
  fontSize: '3rem',
  color: '#ffffff',
  maxWidth: '600px',
  fontWeight: '700',
  letterSpacing: '1px',
};

interface AuthPageLayoutProps extends ChildrenComponent {
  sideImage: string;
}

export default function AuthPageLayout(
  props: AuthPageLayoutProps
): ReactElement<FC> {
  // Destructure the props
  const { children, sideImage } = props;

  return (
    <Container sx={{ minHeight: '100vh' }} disableGutters maxWidth={false}>
      <Grid container>
        <Grid
          sx={{
            backgroundImage: 'url(assets/images/welcome-bg.jpg)',
            ...mainImage,
          }}
          display="flex"
          alignItems="center"
          item
          lg={7}
          padding={4}
        >
          <Typography sx={mainTitle} component="h1">
            Welcome to the Astoria Blogging Platform
          </Typography>
        </Grid>
        <Grid
          display="flex"
          item
          lg={5}
          alignItems="center"
          justifyContent="center"
          padding={4}
        >
          {children}
        </Grid>
      </Grid>
    </Container>
  );
}

// Create the PropTypes
AuthPageLayout.propTypes = {
  children: PropTypes.node,
  sideImage: PropTypes.string,
};
