import { SearchMultiSelectInterface } from '../../utilities/SearchMultiSelectField/SearchMultiSelectInterface';
import { AssignTagsToPost } from '../../../store/reducers/postReducers/postReducer';

export default function extractTagNamesAsArray(
  tags: SearchMultiSelectInterface[]
) {
  let newTags: AssignTagsToPost[] = [];
  if (tags && Array.isArray(tags)) {
    newTags = tags.map((each) => {
      return { name: each.value };
    });
  }
  return newTags;
}
