import React, { FC, ReactElement } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

// User created component imports
import { store, persistor } from '../config/redux/reduxStoreCreator';
import CustomThemeProvider from '../bootstrap/ThemeProvider/CustomThemeProvider';
import ZootashRouter from '../bootstrap/Router/ZootashRouter';

// Import Custom components
import './cssVariables.css';
import RefreshTokens from '../components/utilities/RefreshTokens/RefreshTokens';

function App(): ReactElement<FC> {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CustomThemeProvider>
          <RefreshTokens>
            <BrowserRouter>
              <ZootashRouter />
            </BrowserRouter>
          </RefreshTokens>
        </CustomThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
