import { AnyAction } from 'redux';

// Import custom components
import ActionTypes from '../../actionTypes';
import { User } from '../../reducers/userReducers/userReducer';
import { Auth } from '../../reducers/userReducers/authReducer';

// Initiate Use Login
export function initiateUserLogin(email: string, password: string): AnyAction {
  return {
    type: ActionTypes.initiateUserLogin,
    email,
    password,
  };
}

// Update user Reducer
export function updateUserReducer(user: User): AnyAction {
  return {
    type: ActionTypes.updateUserReducer,
    user,
  };
}

// update Auth Status
export function userLoginSuccess(auth: Auth): AnyAction {
  return {
    type: ActionTypes.userLoginSuccess,
    auth,
  };
}

// update Auth Status
export function failedUserLogin(): AnyAction {
  return {
    type: ActionTypes.failedAttemptToLogin,
    auth: { isAuth: false, accessToken: undefined, failedAttempt: true },
  };
}

// update Auth Status
export function initiateTokenRefresh(accessToken: string): AnyAction {
  return {
    type: ActionTypes.initiateTokenRefresh,
    accessToken,
  };
}

// Reset Auth State
export function resetAuthState(): AnyAction {
  return {
    type: ActionTypes.resetAuthState,
  };
}

// Reset User State
export function resetUserState(): AnyAction {
  return {
    type: ActionTypes.resetUserState,
  };
}

export function clearLocalStorageAndReducers(): AnyAction {
  return {
    type: ActionTypes.clearLocalStorageAndReducers,
  };
}

export function updateRefreshedToken(accessToken: string): AnyAction {
  return {
    type: ActionTypes.updateRefreshedToken,
    accessToken,
  };
}
