import { AnyAction } from 'redux';
import { cloneDeep } from 'lodash';

// Import custom components
import ActionTypes from '../../actionTypes';

export interface Auth {
  isAuth: boolean;
  accessToken: undefined | string;
  failedAttempt: boolean;
}

const initialState: Auth = {
  isAuth: false,
  accessToken: undefined,
  failedAttempt: false,
};

function resetAuth(state: Auth, action: AnyAction) {
  return cloneDeep(action.auth);
}
const updateAccessToken = (state: Auth, action: AnyAction) => {
  return {
    isAuth: true,
    accessToken: action.accessToken,
    failedAttempt: false,
  };
};

const authReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionTypes.userLoginSuccess:
      return resetAuth(state, action);

    case ActionTypes.failedAttemptToLogin:
      return resetAuth(state, action);

    case ActionTypes.updateRefreshedToken:
      return updateAccessToken(state, action);

    case ActionTypes.resetAuthState:
      return { ...initialState };

    default:
      return state;
  }
};

export default authReducer;
