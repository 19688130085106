import React, { FC, ReactElement, useState, useEffect } from 'react';
import { TextField, Button, Typography, CircularProgress } from '@mui/material';
import './loginForm.css';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

// Import custom components
import { initiateUserLogin } from '../../../store/actions/userActions/userActions';
import { FormSubmissionState } from '../../../store/reducers/userInterfaceReducers/formSubmissionStateReducer';
import { RootState } from '../../../store/reducers';

// Error messages
const EmailErrorMessage = 'Provide a valid email address';
const PasswordError = 'Password is required to login';

// Form Schema
const schema = yup.object().shape({
  email: yup.string().email(EmailErrorMessage).required(),
  password: yup.string().required(PasswordError),
});

export default function LoginForm(): ReactElement<FC> {
  // Declare the needed states for the form
  const [email, setEmail] = useState<string | undefined>('');
  const [emailError, setEmailError] = useState<string | undefined>(undefined);
  const [password, setPassword] = useState<string | undefined>('');
  const [disableButton, setDisableButton] = useState<boolean>(true);

  // Declare the dispatch function
  const dispatch = useDispatch();

  // Select the default form state from the root reducer
  const formState: FormSubmissionState = useSelector(
    (state: RootState) => state.formSubmissionStateReducer
  );

  // Validate Email
  useEffect(() => {
    yup
      .reach(schema, 'email')
      .validate(email)
      .then((value: string) => {
        setEmailError(undefined);
        setEmail(value);
      })
      .catch((error: Error) => {
        if (error.message === EmailErrorMessage) {
          setEmailError(EmailErrorMessage);
        }
      });
  }, [email]);

  // Validate complete Form
  useEffect(() => {
    schema
      .validate({
        email,
        password,
      })
      .then((valid) => {
        if (valid) {
          setDisableButton(false);
        }
      })
      .catch((error: Error) => {
        if (error) {
          setDisableButton(true);
        }
      });
  }, [password, email]);

  // Handle form submission
  const handleSubmit = (): void => {
    if (email && password) {
      dispatch(initiateUserLogin(email, password));
    }
  };

  return (
    <form className="loginForm">
      <TextField
        error={!!emailError}
        helperText={emailError ? EmailErrorMessage : false}
        name="email"
        id="email"
        label="Email"
        variant="outlined"
        value={email}
        type="text"
        className="loginFormField"
        onChange={(event) => setEmail(event.target.value)}
      />
      <TextField
        name="password"
        id="password"
        label="Password"
        variant="outlined"
        type="password"
        value={password}
        className="loginFormField"
        onChange={(event) => setPassword(event.target.value)}
      />
      <Button
        size="large"
        disableRipple
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={disableButton}
        sx={{
          position: 'relative',
          minHeight: '48px',
        }}
      >
        {formState.inProcess ? (
          <CircularProgress
            size={24}
            sx={{
              color: '#ffffff',
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        ) : (
          'Login'
        )}
      </Button>
      <Typography marginTop={1} textAlign="end" variant="caption">
        Forgot Password? Click here
      </Typography>
    </form>
  );
}
