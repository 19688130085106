import React, { FC, ReactElement } from 'react';
import { Paper, Typography, Alert, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

// Custom components
import AuthPageLayout from '../../layouts/AuthPageLayout/AuthPageLayout';
import LoginForm from '../../forms/LoginForm/LoginForm';
import './loginPage.css';
import { RootState } from '../../../store/reducers';
import { Auth } from '../../../store/reducers/userReducers/authReducer';
import routePaths from '../../../bootstrap/Router/routePaths';

export default function LoginPage(): ReactElement<FC> {
  // Select the authReducer
  const auth: Auth = useSelector((state: RootState) => state.authReducer);

  if (auth.isAuth) {
    return <Navigate to={routePaths.root} />;
  }

  return (
    <AuthPageLayout sideImage="test">
      <Paper className="authFormBox" variant="outlined">
        <Typography marginBottom={4} variant="h2" component="h2">
          Login To Your Account
        </Typography>
        {auth.failedAttempt ? (
          <Box marginBottom={3}>
            <Alert severity="error">
              Invalid credentials! Either the username or the password is
              incorrect
            </Alert>
          </Box>
        ) : (
          false
        )}
        <LoginForm />
      </Paper>
    </AuthPageLayout>
  );
}
