enum ActionTypes {
  // Root Reducer related actions
  clearLocalStorageAndReducers = 'clearLocalStorageAndReducers',

  // Generic Actions Related to Forms
  formSubmissionStarted = 'formSubmissionStarted',
  formSubmissionEnded = 'formSubmissionEnded',
  clearAllErrors = 'clearAllErrors',

  // Sidebar Actions
  updateSidebar = 'updateSidebar',

  // File Upload
  uploadImageFile = 'uploadImageFile',

  // User Related actions
  initiateUserLogin = 'initiateUserLogin',
  updateUserReducer = 'updateUserReducer',
  userLoginSuccess = 'userLoginSuccess',
  failedAttemptToLogin = 'failedAttemptToLogin',
  initiateTokenRefresh = 'initiateTokenRefresh',
  updateRefreshedToken = 'updateRefreshedToken',
  resetAuthState = 'resetAuthState',
  resetUserState = 'resetUserState',

  // Post related Action
  // => Post Reducer Management
  resetReducerToInitialState = 'resetReducerToInitialState',
  // => Post API Interactions
  createPostOnServer = 'createPostOnServer',
  fetchPostFromServer = 'fetchPostFromServer',
  updatePostOnServer = 'updatePostOnServer',
  updatePostReducerWithServerPost = 'updatePostReducerWithServerPost',
  getAllPosts = 'getAllPosts',
  updateAllPostsReducer = 'updateAllPostsReducer',
  // => Post Settings Form
  changePostStatus = 'changePostStatus',
  updateFeaturedImageUrl = 'updateFeaturedImageUrl',
  featuredImageUploadError = 'featuredImageUploadError',
  updateTagsToPostReducer = 'updateTagsToPostReducer',
  updateContentToPostReducer = 'updateContentToPostReducer',
  updateSchemaToPostReducer = 'updateSchemaToPostReducer',
  updateMetaFieldsToPostReducer = 'updateMetaFieldsToPostReducer',
  updateSlugToPostReducer = 'updateSlugToPostReducer',
  removeFeaturedImageFromReducer = 'removeFeaturedImageFromReducer',

  // Tags related Action
  // => Creating new tags
  initiationTagCreation = 'initiationTagCreation',

  // => Fetching Tags
  initiationGetTags = 'initiationGetTags',
  updateAllTags = 'updateAllTags',
}

export default ActionTypes;
