import React, { MouseEvent } from 'react';
import { Button, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';

// Import custom components
import { ChildrenComponent } from '../../../interfaces/childrenComponent';
import { ButtonColors } from '../../../interfaces/ButtonColors';
import { FormSubmissionState } from '../../../store/reducers/userInterfaceReducers/formSubmissionStateReducer';

// Interface for the component
interface FormSubmittingButtonInterface extends ChildrenComponent {
  color?: ButtonColors | undefined;
  disabled?: boolean;
  onClick?: (
    event: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLButtonElement>
  ) => void;
  fullWidth?: boolean;
}

export default function FormSubmittingButton(
  props: FormSubmittingButtonInterface
) {
  // Destructure props
  const {
    color = 'primary',
    disabled = false,
    onClick = (event) => event,
    children,
    fullWidth,
  } = props;

  // Select the default form state from the root reducer
  const formState: FormSubmissionState = useSelector(
    (state: RootState) => state.formSubmissionStateReducer
  );

  return (
    <Button
      disableElevation
      variant="contained"
      color={color}
      fullWidth={fullWidth}
      disabled={disabled}
      sx={{
        position: 'relative',
        minHeight: '48px',
        minWidth: '200px',
      }}
      onClick={onClick}
    >
      {formState.inProcess ? (
        <CircularProgress
          size={24}
          sx={{
            color: '#ffffff',
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      ) : (
        children
      )}
    </Button>
  );
}

// Decalre the propTypes
FormSubmittingButton.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};
