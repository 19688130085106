import { AnyAction } from 'redux';

// Import custom modules
import ActionTypes from '../../actionTypes';
import { AllPostsInterface } from '../../reducers/postReducers/allPostsReducer';

export function getAllPosts(): AnyAction {
  return {
    type: ActionTypes.getAllPosts,
  };
}

export function updateAllPostsReducer(posts: AllPostsInterface): AnyAction {
  return {
    type: ActionTypes.updateAllPostsReducer,
    posts,
  };
}
