import { JSONContent } from '@tiptap/react';
// Import custom modules
import { Post } from '../store/reducers/postReducers/postReducer';
import { PostOnServerInterface } from '../interfaces/PostOnServerInterface';

export default function convertServerResponseToPost(
  data: PostOnServerInterface,
  localContent?: JSONContent
) {
  let post: Post;
  if (data.content && data.metaOptions.metaValue) {
    post = {
      ...data,
      content: data.content,
      contentOnServer: data.content,
      localContent,
      schema: data.schema || '{}',
      metaOptions: {
        ...data.metaOptions,
        metaValue: JSON.parse(data.metaOptions.metaValue),
      },
    };
  }
  // @ts-ignore
  return post;
}
