import { put, takeEvery } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { AxiosResponse } from 'axios';

// Import custom compoenents
import ActionTypes from '../../actionTypes';
import axiosBaseInstance from '../../../config/axios/axiosBaseInstance';
import { updateAllTags } from '../../actions/tagFormActions/tagFormActions';

export function* getAllTags(action: AnyAction) {
  try {
    const response: AxiosResponse = yield axiosBaseInstance.get('/tags', {});
    if (response.status === 200) {
      yield put(updateAllTags(response.data));
    } else {
      yield console.log(response);
    }
  } catch (errors) {
    yield console.log(errors);
  }
}

export function* getAllTagsSaga() {
  yield takeEvery(ActionTypes.initiationGetTags, getAllTags);
}
