import React from 'react';
import Select, { ActionMeta } from 'react-select';
import makeAnimated from 'react-select/animated';

// Import Custom Components
import { SearchMultiSelectInterface } from './SearchMultiSelectInterface';

const animatedComponents = makeAnimated();

interface MultiSelectField {
  options?: SearchMultiSelectInterface[];
  placeholder?: string;
  onChange?: (newValue: unknown, actionMeta: ActionMeta<unknown>) => void;
  value?: SearchMultiSelectInterface[];
  clearValue?: boolean;
}

export default function SearchMultiSelectField(props: MultiSelectField) {
  // Destaructure props
  const {
    options,
    placeholder = 'Placeholder',
    value,
    clearValue = false,
    onChange = (newValue) => console.log(newValue),
  } = props;
  return (
    <Select
      closeMenuOnSelect={false}
      components={animatedComponents}
      value={value}
      isClearable
      isMulti
      options={options}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
}
