import { AnyAction } from 'redux';
import { cloneDeep } from 'lodash';

// import custom module
import { Post } from './postReducer';
import ActionTypes from '../../actionTypes';
import {
  Links,
  MetaPagination,
} from '../../../interfaces/Pagination.interface';

export interface AllPostsInterface {
  items: Post[];
  link?: Links;
  meta?: MetaPagination;
}

const initialState: AllPostsInterface = {
  items: [],
};

const allPostsReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionTypes.updateAllPostsReducer:
      return { ...action.posts };
    default:
      return state;
  }
};

export default allPostsReducer;
