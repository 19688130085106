import React, {
  FC,
  MouseEventHandler,
  MouseEvent,
  ReactElement,
  ReactNode,
} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { Button } from '@mui/material';
import { AnyAction } from 'redux';

// Import Custom Components
import { ChildrenComponent } from '../../../interfaces/childrenComponent';
import './cardAccordion.scss';

interface CardAccordion extends ChildrenComponent {
  heading?: string;
  id?: string;
  secondaryElement?: ReactNode;
  defaultExpanded?: boolean;
}

export default function CardAccordion(props: CardAccordion): ReactElement<FC> {
  // Destructure props
  const {
    heading = 'heading',
    children,
    id = uuidv4(),
    secondaryElement,
    defaultExpanded = true,
  } = props;
  return (
    <Accordion
      className="cardAccordionWrap"
      defaultExpanded={defaultExpanded}
      variant="outlined"
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
      >
        <Typography variant="h3" component="h2">
          {heading}
        </Typography>
        {secondaryElement ? (
          <Button
            size="small"
            disableElevation
            color="inherit"
            variant="contained"
            onClick={(e) => console.log(e)}
          >
            Copy From Seo Details
          </Button>
        ) : (
          false
        )}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}

CardAccordion.propTypes = {
  heading: PropTypes.string,
  id: PropTypes.string,
  secondaryElement: PropTypes.node,
};
