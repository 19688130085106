import React, { FC, ReactElement } from 'react';
import { TextField, Box } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';

// Import custom components
import './dateTimePickerField.scss';

export default function DateTimePickerField() {
  const [value, setValue] = React.useState<Date | null>(null);

  return (
    <Box className="dateTimePickerField">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          disablePast
          label="Schedule Date | Time"
          openTo="day"
          minDateTime={new Date()}
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </Box>
  );
}
