import { put, takeEvery } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { AxiosResponse } from 'axios';

// Import custom compoenents
import ActionTypes from '../../actionTypes';
import axiosBaseInstance from '../../../config/axios/axiosBaseInstance';
import {
  updateUserReducer,
  userLoginSuccess,
  failedUserLogin,
} from '../../actions/userActions/userActions';
import {
  formSubmissionStarted,
  formSubmissionEnded,
} from '../../actions/genericFormActions/genericFormActions';

export function* loginSaga(action: AnyAction) {
  yield put(formSubmissionStarted());
  try {
    const response: AxiosResponse = yield axiosBaseInstance.post('auth/login', {
      email: action.email,
      password: action.password,
    });
    if (response.status === 201) {
      yield put(updateUserReducer(response.data));
      yield put(
        userLoginSuccess({
          isAuth: true,
          accessToken: response.data.accessToken,
          failedAttempt: false,
        })
      );
      yield put(formSubmissionEnded());
    } else {
      yield put(failedUserLogin());
      yield put(formSubmissionEnded());
    }
  } catch (errors) {
    yield put(formSubmissionEnded());
    yield put(failedUserLogin());
  }
}

export function* userLoginSaga() {
  yield takeEvery(ActionTypes.initiateUserLogin, loginSaga);
}
