import { AnyAction } from 'redux';

// Import custom components
import ActionTypes from '../../actionTypes';
import {
  TagInterface,
  AllTagsInterface,
} from '../../reducers/tagsReducers/allTagsReducer';

export function initiationTagCreation(tag: TagInterface): AnyAction {
  return {
    type: ActionTypes.initiationTagCreation,
    tag,
  };
}

export function initiateGetAllTags(): AnyAction {
  return {
    type: ActionTypes.initiationGetTags,
  };
}

export function updateAllTags(tags: AllTagsInterface): AnyAction {
  return {
    type: ActionTypes.updateAllTags,
    tags,
  };
}
