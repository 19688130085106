import { AnyAction } from 'redux';

// Import Custom Components
import ActionTypes from '../../actionTypes';

export function initiateUploadFile(
  file: string,
  uploadType: string
): AnyAction {
  return {
    type: ActionTypes.uploadImageFile,
    file,
    uploadType,
  };
}
