import React, { ReactElement, FC } from 'react';
import { Box, Button } from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// Import Custom Modules
import { removeFeaturedImageFromReducer } from '../../../store/actions/postFormsActions/postSettingsFormActions';

interface FeaturedImageInterface {
  image: string;
}

export default function FeaturedImage(props: FeaturedImageInterface) {
  // Destructure props
  const { image } = props;
  // Declare dispatch function
  const dispatch = useDispatch();

  return (
    <>
      <Box
        sx={{
          width: '100%',
          backgroundImage: `url(${image})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: '200px',
        }}
      />
      <Box display="flex" justifyContent="flex-end">
        <Button
          onClick={() => dispatch(removeFeaturedImageFromReducer())}
          color="inherit"
        >
          Change Image
        </Button>
      </Box>
    </>
  );
}

FeaturedImage.propTypes = {
  image: PropTypes.string,
};
