import { JSONContent } from '@tiptap/react';
import { updateSlugToPostReducer } from '../../../store/actions/postFormsActions/postSettingsFormActions';

export default function createSlugFromTitle(content: JSONContent) {
  let slug = '';
  if (Array.isArray(content)) {
    if (content[0]) {
      if (
        Array.isArray(content[0].content) &&
        content[0].content[0] !== undefined
      ) {
        slug = content[0].content[0].text
          .trim()
          .replace(/[^a-zA-Z ]/g, '')
          .toLowerCase()
          .replace(/\s+/g, '-');
      }
    }
  }
  return slug;
}
