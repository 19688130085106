import React from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

// Import custom components
import { Post } from '../../../store/reducers/postReducers/postReducer';
import routePaths from '../../../bootstrap/Router/routePaths';

interface SinglePostForTable {
  id?: string;
  title?: string;
  user?: string;
  status?: string;
  eachId?: string;
}

export default function ConvertPostsDataToAllPostsTable(posts: Post[]) {
  let newposts: SinglePostForTable[] = [];
  if (Array.isArray(posts) && posts.length > 0) {
    newposts = posts.map((each) => {
      return {
        id: each.id,
        title: each.title,
        user: each.user?.firstName,
        status: each.status,
        eachId: each.id,
        actions: <Link to={`${routePaths.editPost}/${each.id}`}>Edit</Link>,
      };
    });
  }
  return newposts;
}
