import { put, takeEvery } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { AxiosResponse } from 'axios';

// Import custom compoenents
import ActionTypes from '../../actionTypes';
import axiosBaseInstance from '../../../config/axios/axiosBaseInstance';
import {
  formSubmissionStarted,
  formSubmissionEnded,
} from '../../actions/genericFormActions/genericFormActions';
import { updateAllPostsReducer } from '../../actions/postFormsActions/gettingPostActions';

export function* fetchAllPosts(action: AnyAction) {
  yield put(formSubmissionStarted());
  try {
    const response: AxiosResponse = yield axiosBaseInstance.get(`/posts`);
    if (response.status === 200) {
      yield put(updateAllPostsReducer(response.data));
      yield put(formSubmissionEnded());
    } else {
      yield put(formSubmissionEnded());
    }
  } catch (errors) {
    yield put(formSubmissionEnded());
  }
}

export function* fetchAllPostsSaga() {
  yield takeEvery(ActionTypes.getAllPosts, fetchAllPosts);
}
