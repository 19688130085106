import { AssignTagsToPost } from '../../../store/reducers/postReducers/postReducer';
import { SearchMultiSelectInterface } from '../../utilities/SearchMultiSelectField/SearchMultiSelectInterface';

export default function convertTagsArrayToSelectCompliant(
  tags: AssignTagsToPost[]
): SearchMultiSelectInterface[] {
  let multiSelectOptions: SearchMultiSelectInterface[] = [];

  if (tags && Array.isArray(tags)) {
    multiSelectOptions = tags.map((each) => {
      return {
        value: each.name,
        label: each.name,
      };
    });
  }
  return multiSelectOptions;
}
