import React, {
  FC,
  ReactElement,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { TextField, Box, Button, Typography } from '@mui/material';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';

// Import custom components
import FormSubmittingButton from '../../utilities/FormSubmittingButton/FormSubmittingButton';
import { initiationTagCreation } from '../../../store/actions/tagFormActions/tagFormActions';

// Error messages
const NameErrorMessage = 'Name is a mandatory field to create a Tag';
const MinNameLength = 'Name needs to have a minimum length of 4 characters';
const MinSlugLength = 'Name needs to have a minimum length of 5 characters';
const SlugShapeError = `The slug can contain alphabets, numbers and '-' with no spaces`;

// Form Schema
const schema = yup.object().shape({
  name: yup.string().min(4, MinNameLength).required(NameErrorMessage),
  slug: yup
    .string()
    .min(5, MinSlugLength)
    .matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, SlugShapeError)
    .required(),
});

export default function AddTagForm(): ReactElement<FC> {
  // Create the dispatch Function
  const dispatch = useDispatch();

  // Declare form states
  const [name, setName] = useState<string | undefined>(undefined);
  const [nameError, setNameError] = useState<string | undefined>(undefined);
  const [slug, setSlug] = useState<string | undefined>(undefined);
  const [slugError, setSlugError] = useState<string | undefined>(undefined);
  const [description, setDescription] = useState<string | undefined>(undefined);
  const [disabledButton, setDisabledButton] = useState<boolean | undefined>(
    true
  );
  const [formSubmitted, setFormSubmitted] = useState<number>(1);

  // Reset States Callback on form Submission
  const resetStates = useCallback(() => {
    setName(undefined);
    setNameError(undefined);
    setSlug(undefined);
    setSlugError(undefined);
    setDescription(undefined);
    setDisabledButton(true);
  }, []);

  // UseEffect to validate the name of the slug
  useEffect(() => {
    if (name !== undefined) {
      yup
        .reach(schema, 'name')
        .validate(name)
        .then((value: string) => {
          setNameError(undefined);
          setName(value);
        })
        .catch((error: Error) => {
          if (error.message === NameErrorMessage) {
            setNameError(NameErrorMessage);
          }
          if (error.message === MinNameLength) {
            setNameError(MinNameLength);
          }
        });
    }
  }, [name]);

  //
  useEffect(() => {
    if (slug !== undefined) {
      yup
        .reach(schema, 'slug')
        .validate(slug)
        .then((value: string) => {
          setSlugError(undefined);
          setSlug(value);
        })
        .catch((error: Error) => {
          if (error.message === MinSlugLength) {
            setSlugError(MinSlugLength);
          }
          if (error.message === SlugShapeError) {
            setSlugError(SlugShapeError);
          }
        });
    }
  }, [slug]);

  useEffect(() => {
    schema
      .validate({ name, slug })
      .then((valid) => {
        if (valid) {
          setDisabledButton(false);
        }
      })
      .catch((errors) => {
        setDisabledButton(true);
      });
  }, [name, slug]);

  // Use Effect to re-reder the form once it has been submitted
  useEffect(() => {
    resetStates();
  }, [formSubmitted]);

  // Handle Form Submission
  function handleFormSubmission() {
    if (name && slug) {
      dispatch(
        initiationTagCreation({
          name,
          slug,
          description,
        })
      );
      setFormSubmitted(formSubmitted + 1);
    }
  }

  return (
    <form>
      <TextField
        helperText={nameError || null}
        error={!!nameError}
        size="medium"
        id="tag-name"
        sx={{ marginBottom: '1.125rem' }}
        fullWidth
        value={name || ''}
        label="Tag Name"
        name="name"
        placeholder="Tag Name"
        onChange={(e) => setName(e.target.value.trim())}
        variant="outlined"
      />
      <TextField
        size="medium"
        error={!!slugError}
        sx={{ marginBottom: '1.125rem' }}
        id="slug"
        fullWidth
        value={slug || ''}
        label="Slug"
        name="slug"
        placeholder="Slug"
        variant="outlined"
        onChange={(e) => setSlug(e.target.value.trim())}
        helperText={
          slugError || 'A slug is a url for the tag. Format: this-is-a-slug'
        }
      />
      <TextField
        size="medium"
        sx={{ marginBottom: '1.125rem' }}
        id="description"
        fullWidth
        value={description || ''}
        label="Description"
        name="description"
        placeholder="Description"
        variant="outlined"
        onChange={(event) => setDescription(event.target.value.trim())}
        multiline
        minRows={3}
      />
      <Box display="flex" justifyContent="flex-end">
        <FormSubmittingButton
          onClick={() => handleFormSubmission()}
          disabled={disabledButton}
        >
          Add New Tag
        </FormSubmittingButton>
      </Box>
    </form>
  );
}
