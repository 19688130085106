import { AnyAction } from 'redux';

// Import Custom Components
import { MetaFieldsInterface } from '../../reducers/postReducers/postReducer';
import ActionTypes from '../../actionTypes';

export function updateMetaFields(payload: MetaFieldsInterface): AnyAction {
  return {
    type: ActionTypes.updateMetaFieldsToPostReducer,
    payload,
  };
}
