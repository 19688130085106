import { Action } from 'redux';

// Custom user imports
import { SidebarObjectInterface } from '../../../interfaces/sidebarLinksInterfaces';
import sidebarObject from '../../../config/navbarObjects/sidebarObject';

const initialState: SidebarObjectInterface[] = sidebarObject;

const sidebarReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default sidebarReducer;
