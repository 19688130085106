// TODO: Look at the possiblity of impoerting the Auth Token from the stor eusing middleware
// https://stackoverflow.com/questions/38460949/what-is-the-best-way-to-access-redux-store-outside-a-react-component
import axios from 'axios';
import settings from '../settings';

// Create the Axios instance
const axiosBaseInstance = axios.create({
  // .. where we make our configurations
  baseURL: settings.app.apiEndpoint,
});

// Where you would set stuff like your 'Authorization' header, etc ...
// axiosBaseInstance.defaults.headers.common['Authorization'] = 'AUTH TOKEN FROM INSTANCE';

// Example Interceptors If Needed To Added to Instance
/*
axiosBaseInstance.interceptors.request.use(function (config) {
	// Do something before request is sent
	return config;
}, function (error) {
	// Do something with request error
	return Promise.reject(error);
});

// Add a response interceptor
axiosBaseInstance.interceptors.response.use(function (response) {
	// Any status code that lie within the range of 2xx cause this function to trigger
	// Do something with response data
	return response;
}, function (error) {
	// Any status codes that falls outside the range of 2xx cause this function to trigger
	// Do something with response error
	return Promise.reject(error);
});
*/

export default axiosBaseInstance;
