import { put, takeEvery } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { AxiosResponse } from 'axios';

// Import custom compoenents
import ActionTypes from '../../actionTypes';
import axiosBaseInstance from '../../../config/axios/axiosBaseInstance';
import getCurrentUser from '../../../config/getCurrentUser/getCurrentUser';
import { User } from '../../reducers/userReducers/userReducer';
import {
  formSubmissionStarted,
  formSubmissionEnded,
} from '../../actions/genericFormActions/genericFormActions';
import { updatePostReducerWithServerPost } from '../../actions/postFormsActions/savingPostActions';
import preparePostToSaveOnServer from '../../../helpers/preparePostToSaveOnServer';
import convertServerResponseToPost from '../../../helpers/convertServerResponseToPost';

export function* updatePost(action: AnyAction) {
  const user: User = yield getCurrentUser();
  yield put(formSubmissionStarted());
  try {
    const response: AxiosResponse = yield axiosBaseInstance.put(
      '/posts/update',
      preparePostToSaveOnServer(action.post),
      {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      }
    );
    if (response.status === 200) {
      yield put(
        updatePostReducerWithServerPost(
          convertServerResponseToPost(response.data, action.post.localContent)
        )
      );
      yield put(formSubmissionEnded());
    } else {
      yield put(formSubmissionEnded());
    }
  } catch (errors) {
    yield put(formSubmissionEnded());
  }
}

export function* updatePostOnServerSaga() {
  yield takeEvery(ActionTypes.updatePostOnServer, updatePost);
}
