import { AnyAction } from 'redux';
import { cloneDeep } from 'lodash';

// Import custom components
import {
  Links,
  MetaPagination,
} from '../../../interfaces/Pagination.interface';
import ActionTypes from '../../actionTypes';

export interface TagInterface {
  id?: string;
  name: string;
  slug: string;
  description?: string;
  schema?: string;
  featuredImage?: string;
}

export interface AllTagsInterface {
  items: TagInterface[];
  links?: Links;
  meta?: MetaPagination;
}

const initialState: AllTagsInterface = {
  items: [],
};

// Function to update all the tags
function updateAllTags(state: AllTagsInterface, action: AnyAction) {
  return cloneDeep(action.tags);
}

const allTagsReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionTypes.updateAllTags:
      return updateAllTags(state, action);

    default:
      return state;
  }
};

export default allTagsReducer;
