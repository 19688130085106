import React, { FC, ReactElement } from 'react';
import { Chip, Avatar } from '@mui/material';
import PropTypes from 'prop-types';

// import custom components
import './topbarProfileButton.css';

interface TopbarProfileButton {
  name: string;
  image?: string;
}

export default function TopbarProfileButton(
  props: TopbarProfileButton
): ReactElement<FC> {
  // Destruture props
  const { name, image } = props;

  return image ? (
    <Chip
      size="medium"
      clickable
      className="topbarProfileButton"
      avatar={<Avatar alt={`${name}'s image`} src={image} color="primary" />}
      label="Welcome, Manik"
    />
  ) : (
    <Chip
      size="medium"
      clickable
      className="topbarProfileButton"
      avatar={<Avatar color="primary">{name[0]}</Avatar>}
      label="Welcome, Manik"
    />
  );
}

TopbarProfileButton.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string,
};
