import React, { FC, ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';

// Import custom components
import routePaths from '../../../bootstrap/Router/routePaths';

export default function ProtectedRoutes(): ReactElement<FC> {
  // select the auth reducer
  const auth = useSelector((state: RootState) => state.authReducer);

  // Return
  return auth.isAuth && auth.accessToken ? (
    <Outlet />
  ) : (
    <Navigate to={routePaths.login} />
  );
}
