import React, { FC, ReactElement, useEffect, useState } from 'react';
import { Grid, Box } from '@mui/material';
import { useParams, Params, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useBeforeunload } from 'react-beforeunload';

// Import custom components
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout';
import CardWidget from '../../layouts/CardWidget/CardWidget';
import PostEditor from '../../utilities/TipTapAndMonaco/PostEditor';
import PostMetaOptions from '../../forms/PostMetaOptions/PostMetaOptions';
import SearchEngineOptions from '../../forms/SearchEngineOptions/SearchEngineOptions';
import FacebookOptions from '../../forms/FacebookOptions/FacebookOptions';
import TwitterOptions from '../../forms/TwitterOptions/TwitterOptions';
import PostSettingsForm from '../../forms/PostSettingsForm/PostSettingsForm';
import { initiateGetAllTags } from '../../../store/actions/tagFormActions/tagFormActions';
import { RootState } from '../../../store/reducers';
import routePaths from '../../../bootstrap/Router/routePaths';
import { resetReducerToInitialState } from '../../../store/actions/postFormsActions/reducerManagamentActions';
import { fetchPostFromServer } from '../../../store/actions/postFormsActions/savingPostActions';
import { Post } from '../../../store/reducers/postReducers/postReducer';
import { consoleLogDetails } from '../../../helpers/consoleLogDetails';
import { updateSchemaToPostReducer } from '../../../store/actions/postFormsActions/monacoEditorActions';
import MonacoEditor from '../../utilities/MonacoEditor/MonacoEditor';
import SchemaObject from '../../forms/SchemaObject/SchemaObject';

export default function AddNewPost(): ReactElement<FC> {
  /*
   * ===========
   * Initializers
   * ===========
   * */
  // Create the dispatch function
  const dispatch = useDispatch();
  // use the Params hook
  const params: Params = useParams();

  /*
   * ===========
   * Reducers
   * ===========
   * */
  const post: Post = useSelector((state: RootState) => state.postReducer);

  /*
   * ===========
   * State
   * ===========
   * */
  // State to check if post is saved
  const [saved, setSaved] = useState<boolean>(false);
  /*
   * ===========
   * Sideffects
   * ===========
   * */
  // UseEffect to navigate to relevant URL once the ID is set
  useEffect(() => {
    if (params.id) {
      <Navigate to={`${routePaths.editPost}/${params.id}`} />;
    }
  }, [params]);

  // UseEffect to navigate to relevant URL if Params have a set ID
  useEffect(() => {
    if (params.id) {
      dispatch(fetchPostFromServer(params.id));
    }
    if (!params.id) {
      dispatch(resetReducerToInitialState());
    }
  }, [params]);

  // UseEffect to dispatch and get all tags
  useEffect(() => {
    dispatch(initiateGetAllTags());
  }, []);

  // Hook to check if the user is releading page without saving
  useBeforeunload((event: Event) => {
    if (!saved) {
      event.preventDefault();
      // eslint-disable-next-line no-restricted-globals,no-alert
      confirm('Changes that you made may not be saved.');
    }
  });

  return (
    <form>
      <DashboardLayout>
        <Grid item lg={9}>
          {/* Monaco and TipTap Editor */}
          <Box sx={{ marginBottom: '1.5rem' }} className="tipTapEditorWrapper">
            <PostEditor />
          </Box>

          {/* Post Meta Options */}
          <Box sx={{ marginBottom: '1.5rem' }}>
            <SchemaObject />
          </Box>
          <Box sx={{ marginBottom: '1.5rem' }}>
            <PostMetaOptions />
          </Box>

          {/* Search Engine Options */}
          <Box sx={{ marginBottom: '1.5rem' }}>
            <SearchEngineOptions />
          </Box>

          {/* Facebook Options */}
          <Box sx={{ marginBottom: '1.5rem' }}>
            <FacebookOptions />
          </Box>

          {/* Twitter Options */}
          <Box sx={{ marginBottom: '1.5rem' }}>
            <TwitterOptions />
          </Box>
        </Grid>

        {/* Post settings Sidebar */}
        <Grid item lg={3}>
          <CardWidget button buttonText="Preview" heading="Post Settings">
            <PostSettingsForm />
          </CardWidget>
        </Grid>
      </DashboardLayout>
    </form>
  );
}
