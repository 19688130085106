import { put, takeEvery } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { AxiosResponse } from 'axios';

// Import custom compoenents
import ActionTypes from '../../actionTypes';
import axiosBaseInstance from '../../../config/axios/axiosBaseInstance';
import getCurrentUser from '../../../config/getCurrentUser/getCurrentUser';
import {
  userLoginSuccess,
  resetAuthState,
  resetUserState,
  clearLocalStorageAndReducers,
  updateRefreshedToken,
} from '../../actions/userActions/userActions';
import { User } from '../../reducers/userReducers/userReducer';

export function* refreshToken(action: AnyAction) {
  const user: User = yield getCurrentUser();
  console.log(`Firing Refresh Token ${new Date()}`);
  try {
    const response: AxiosResponse = yield axiosBaseInstance.post(
      '/auth/refresh-token',
      {},
      {
        headers: {
          Authorization: `Bearer ${action.accessToken}`,
        },
      }
    );
    yield console.log(`New Token: ${response.data.accessToken}`);
    if (response.status === 201) {
      yield put(updateRefreshedToken(response.data.accessToken));
    } else {
      yield put(resetAuthState());
      yield put(resetUserState());
      yield put(clearLocalStorageAndReducers());
    }
  } catch (errors) {
    yield console.log(errors);
    yield put(resetAuthState());
    yield put(resetUserState());
    yield put(clearLocalStorageAndReducers());
  }
  yield console.log(`Access Token In User: ${user.accessToken}`);
}

export function* refreshTokenSaga() {
  yield takeEvery(ActionTypes.initiateTokenRefresh, refreshToken);
}
