import { JSONContent } from '@tiptap/react';

export default function extractTitleFromContent(json: JSONContent) {
  let title = '';
  // Destructure content from JSON
  const { content } = json;
  if (!content) {
    return '';
  }
  if (Array.isArray(content) && content[0] !== undefined) {
    if (Array.isArray(content[0].content)) {
      if (content[0].content[0].text !== undefined) {
        title = content[0].content[0].text;
      }
    }
  }
  return title;
}
