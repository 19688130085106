enum routePaths {
  root = '/',
  login = '/login',
  allPosts = '/posts',
  addNewPost = '/posts/add-new',
  editPost = '/posts/edit',
  addTags = '/tags/add-new',
}

export default routePaths;
