import React, { useEffect, ReactElement, FC, MouseEvent } from 'react';
import { Button, Grid } from '@mui/material';
import {
  DataGrid,
  GridCellValue,
  GridColDef,
  GridRenderCellParams,
  GridStateColDef,
} from '@mui/x-data-grid';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

/*
 * ========================
 * Import custom components
 * ========================
 * */
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout';
import './allPosts.scss';
import CardWidget from '../../layouts/CardWidget/CardWidget';
import { getAllPosts } from '../../../store/actions/postFormsActions/gettingPostActions';
import { RootState } from '../../../store/reducers';
import ConvertPostsDataToAllPostsTable from './convertPostsDataToAllPostsTable';
import routePaths from '../../../bootstrap/Router/routePaths';

/*
 * ============================
 * Component Specific Functions
 * ============================
 * */
const columns: GridColDef[] = [
  {
    field: 'title',
    headerName: 'Post Title',
    width: 450,
    editable: true,
  },
  {
    field: 'user',
    headerName: 'Author',
    width: 300,
    editable: true,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'status',
    headerName: 'Status',
    type: 'string',
    width: 200,
    align: 'center',
    headerAlign: 'center',
    editable: true,
  },
  {
    field: 'eachId',
    headerName: 'ID',
    type: 'string',
    hide: true,
    editable: true,
  },
  {
    field: 'action',
    headerName: 'Action',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      const navigate = useNavigate();
      const onClick = (
        e: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLButtonElement>
      ) => {
        e.stopPropagation(); // don't select this row after clicking

        const { api } = params;
        const thisRow: Record<string, GridCellValue> = {};

        api
          .getAllColumns()
          .filter((c: GridStateColDef) => c.field !== '__check__' && !!c)
          .forEach(
            // eslint-disable-next-line no-return-assign
            (c: GridStateColDef) =>
              (thisRow[c.field] = params.getValue(params.id, c.field))
          );

        return navigate(`${routePaths.editPost}/${thisRow.eachId}`);
      };
      return (
        <Button
          onClick={onClick}
          disableElevation
          size="small"
          variant="contained"
          color="inherit"
        >
          View
        </Button>
      );
    },
  },
];

export default function AllPosts(): ReactElement<FC> {
  /*
   * =============
   * Initializers
   * =============
   * */
  // Declare the dispatch method
  const dispatch = useDispatch();

  /*
   * ===================
   * Reducers Selectors
   * ==================
   * */
  const allPosts = useSelector((state: RootState) => state.allPostsReducer);
  const postRows = ConvertPostsDataToAllPostsTable(allPosts.items);

  /*
   * ========
   * States
   * ========
   * */

  /*
   * =============
   * CallBacks
   * =============
   * */

  /*
   * =============
   * SideEffects
   * =============
   * */
  // Get all posts when the page loads
  useEffect(() => {
    dispatch(getAllPosts());
  }, []);

  /*
   * =============
   * Handlers
   * =============
   * */

  return (
    <DashboardLayout>
      <Grid item lg={12}>
        <CardWidget
          style={{ paddingBottom: '75px', height: '650px', width: '100%' }}
          heading="All Posts"
        >
          <DataGrid
            rows={allPosts.items ? postRows : []}
            getRowId={(row) => row.id}
            columns={columns}
            pageSize={15}
            rowsPerPageOptions={[15]}
            checkboxSelection
            disableSelectionOnClick
          />
        </CardWidget>
      </Grid>
    </DashboardLayout>
  );
}
