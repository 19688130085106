import React, { FC, ReactElement } from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';

//  Import custom components
import CardAccordion from '../../layouts/CardAccordion/CardAccordion';
import { updateSchemaToPostReducer } from '../../../store/actions/postFormsActions/monacoEditorActions';
import MonacoEditor from '../../utilities/MonacoEditor/MonacoEditor';
import { Post } from '../../../store/reducers/postReducers/postReducer';
import { RootState } from '../../../store/reducers';

export default function SchemaObject(): ReactElement<FC> {
  /*
   * ========
   * Reducers
   * =========
   * */
  const post: Post = useSelector((state: RootState) => state.postReducer);

  return (
    <CardAccordion defaultExpanded={false} heading="Schema Object (JSON-ld)">
      <Grid spacing={3} container>
        <Grid item lg={12}>
          <MonacoEditor
            monacoDefaultValue={post.schema}
            height="60vh"
            onMonacoChange={updateSchemaToPostReducer}
          />
        </Grid>
      </Grid>
    </CardAccordion>
  );
}
