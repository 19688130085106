import React, { FC, ReactElement } from 'react';
import Editor, {
  DiffEditor,
  useMonaco,
  loader,
  OnChange,
} from '@monaco-editor/react';
import PropTypes from 'prop-types';
import { AnyAction } from 'redux';
import { useDispatch } from 'react-redux';

// import custom components

export interface MonacoEditorProps {
  height?: string;
  onMonacoChange?: (value: string | undefined) => AnyAction;
  monacoDefaultValue: string;
}

export default function MonacoEditor(
  props: MonacoEditorProps
): ReactElement<FC> {
  // Destructure props
  const {
    height = '30vh',
    onMonacoChange = (value) => value,
    monacoDefaultValue = '{}',
  } = props;
  // Declare dispatch fucntion
  const dispatch = useDispatch();
  return (
    <Editor
      height={height}
      defaultLanguage="json"
      value={monacoDefaultValue}
      theme="vs-dark"
      onChange={(value) => dispatch(onMonacoChange(value))}
      options={{
        fontSize: 18,
        lineHeight: 1.6,
      }}
    />
  );
}

MonacoEditor.propTypes = {
  height: PropTypes.string,
};
