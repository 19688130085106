import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';

// Import custom components
import './simpleActionList.scss';

interface SimpleActionListInterface {
  title: string;
}

export default function SimpleActionList(props: SimpleActionListInterface) {
  // Destructure props
  const { title } = props;
  return (
    <Box className="simpleActionListWrap">
      <Typography marginBottom={0}>{title}</Typography>
      <IconButton size="small" aria-label="Option">
        <MoreVertIcon />
      </IconButton>
    </Box>
  );
}

SimpleActionList.propTypes = {
  title: PropTypes.string,
};
