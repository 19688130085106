import React, { FC, ReactElement } from 'react';
import { Switch, Box, Typography, IconButton, Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import PropTypes from 'prop-types';

// Import custom components
import './metaOptionsSwitch.css';

interface MetaOptionsSwitch {
  label: string;
  helpText?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function MetaOptionsSwitch(
  props: MetaOptionsSwitch
): ReactElement<FC> {
  // Destructure props
  const { label, helpText = '', onChange } = props;

  return (
    <Box className="metaOptionsSwitchWrapper">
      <Typography variant="h6" component="p">
        <span style={{ marginRight: '.5rem' }}>{label}</span>
        {helpText.length > 0 ? (
          <Tooltip placement="top" title={helpText}>
            <IconButton size="small">
              <HelpIcon />
            </IconButton>
          </Tooltip>
        ) : (
          false
        )}
      </Typography>
      <Switch onChange={onChange} defaultChecked aria-label={label} />
    </Box>
  );
}

MetaOptionsSwitch.propTypes = {
  label: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
