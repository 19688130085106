import React, {
  FC,
  MouseEvent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Alert,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { SelectChangeEvent } from '@mui/material/Select';

// Import custom components
import { PostStatus } from '../../../enums/PostStatus';
import CustomHelperTextForUrl from './CustomHelperTextForUrl/CustomHelperTextForUrl';
import SearchMultiSelectField from '../../utilities/SearchMultiSelectField/SearchMultiSelectField';
import DropzoneFileUploader from '../../utilities/DropzoneFileUploader/DropzoneFileUploader';
import DateTimePickerField from '../../utilities/DateTimePickerField/DateTimePickerField';
import { RootState } from '../../../store/reducers';
import { Post } from '../../../store/reducers/postReducers/postReducer';
import {
  changePostStatus,
  updateSlugToPostReducer,
  updateTagsToPostReducer,
} from '../../../store/actions/postFormsActions/postSettingsFormActions';
import { AppErrors } from '../../../store/reducers/coreReducers/ErrorReducer';
import { FileUploadTypes } from '../../../enums/FileUploadTypes';
import { AllTagsInterface } from '../../../store/reducers/tagsReducers/allTagsReducer';
import convertTagsToMultiselectFormat from '../../utilities/SearchMultiSelectField/convertTagsToMultiselectFormat';
import extractTagNamesAsArray from './extractTagNamesAsArray';
import convertTagsArrayToSelectCompliant from './convertTagsArrayToSelectCompliant';
import FeaturedImage from '../../utilities/FeaturedImage/FeaturedImage';
import { initiateUploadFile } from '../../../store/actions/fileUploadActions/fileUploadActions';
import createSlugFromTitle from './createSlugFromTitle';
import {
  createPostOnServer,
  updatePostOnServer,
} from '../../../store/actions/postFormsActions/savingPostActions';
import FormSubmittingButton from '../../utilities/FormSubmittingButton/FormSubmittingButton';

// Function to render correct Button Text
const returnCorrectButtonText = (postStatus: string): string => {
  if (postStatus === PostStatus.SCHEDULED) {
    return 'Schedule Now';
  }
  if (postStatus === PostStatus.PUBLISHED) {
    return 'Publish now';
  }
  return 'Save';
};

export default function PostSettingsForm(): ReactElement<FC> {
  /*
   * =============
   * Initializers
   * =============
   * */
  // use the dispatch hook
  const dispatch = useDispatch();

  /*
   * ==========
   * Reducers
   * ==========
   * */
  // Get the post reducer from redux
  const post: Post = useSelector((state: RootState) => state.postReducer);

  // Get the all Tags reducer from redux
  const allTags: AllTagsInterface = useSelector(
    (state: RootState) => state.allTagsReducer
  );
  // Select the error object from reducer
  const appErrors: AppErrors = useSelector(
    (state: RootState) => state.errorReducer
  );

  /*
   * ========
   * States
   * ========
   * */
  // Create a state for disabled button
  const [disabledButton, setDisabledButton] = useState<boolean>(true);

  /*
   * =============
   * CallBacks
   * =============
   * */
  // Tags Change Handler
  const tagsChangeHandler = useCallback((value) => {
    dispatch(updateTagsToPostReducer(extractTagNamesAsArray(value)));
  }, []);

  /*
   * =============
   * SideEffects
   * =============
   * */
  // Update Slug On Title Change
  useEffect(() => {
    if (
      !post.id &&
      post.localContent &&
      Array.isArray(post.localContent.content)
    ) {
      dispatch(
        updateSlugToPostReducer(createSlugFromTitle(post.localContent.content))
      );
    }
  }, [post.localContent]);

  useEffect(() => {
    if (post.slug && post.localContent && post.title) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }, [post.slug, post.title, post.localContent]);

  /*
   * =============
   * Handlers
   * =============
   * */
  // Post status change handler
  const postStatusChange = (event: SelectChangeEvent) => {
    dispatch(changePostStatus(event.target.value));
  };

  // Handler function for save button click
  const handleSaveButtonClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLButtonElement>) => {
      if (!post.id) {
        e.preventDefault();
        dispatch(createPostOnServer(post));
      } else {
        e.preventDefault();
        dispatch(updatePostOnServer(post));
      }
    },
    [post]
  );

  return (
    <>
      <Box marginBottom="1.5rem">
        <TextField
          size="small"
          fullWidth
          value={post.slug ? post.slug : ' '}
          label="Post Url"
          disabled
          placeholder="url-of-your-post"
          variant="outlined"
          helperText={<CustomHelperTextForUrl link="/" />}
        />
      </Box>
      <Box marginBottom="1.5rem">
        <FormControl fullWidth>
          <InputLabel id="selectPostStatus">Post Status</InputLabel>
          <Select
            labelId="PostStatus"
            id="postStatus"
            label="Post Status"
            defaultValue={PostStatus.DRAFT}
            size="small"
            value={post.status}
            onChange={(e) => postStatusChange(e)}
          >
            <MenuItem value={PostStatus.DRAFT}>Draft</MenuItem>
            <MenuItem value={PostStatus.PUBLISHED}>Publish</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {post.status === PostStatus.SCHEDULED ? (
        <Box marginBottom="1.5rem">
          <DateTimePickerField />
        </Box>
      ) : (
        false
      )}
      <Box marginBottom="1.5rem">
        <FormSubmittingButton
          fullWidth
          disabled={disabledButton}
          onClick={(e) => handleSaveButtonClick(e)}
        >
          {returnCorrectButtonText(post.status)}
        </FormSubmittingButton>
      </Box>
      <Box marginBottom="1.5rem">
        <SearchMultiSelectField
          options={convertTagsToMultiselectFormat(allTags)}
          placeholder="Post Tags"
          onChange={(value) => tagsChangeHandler(value)}
          value={
            post.tags ? convertTagsArrayToSelectCompliant(post.tags) : undefined
          }
        />
      </Box>
      {appErrors && appErrors.featureImageUpload.error ? (
        <Box marginBottom="1.5rem">
          <Alert severity="error" color="error">
            {appErrors.featureImageUpload.message}
          </Alert>
        </Box>
      ) : (
        false
      )}
      <Box marginBottom="1.5rem">
        {post.featuredImage ? (
          <FeaturedImage image={post.featuredImage} />
        ) : (
          <DropzoneFileUploader
            heading="Upload Featured Image"
            fileUploadType={FileUploadTypes.featuredImage}
            onDropAction={initiateUploadFile}
          />
        )}
      </Box>
    </>
  );
}
