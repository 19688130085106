import { AnyAction } from 'redux';
import { HTMLContent, JSONContent } from '@tiptap/react';

// import custom modules
import ActionTypes from '../../../actionTypes';
import { consoleLogDetails } from '../../../../helpers/consoleLogDetails';

export function updateContentToPostReducer(
  content: HTMLContent,
  title: string,
  localContent: JSONContent
): AnyAction {
  return {
    type: ActionTypes.updateContentToPostReducer,
    content,
    title,
    localContent,
  };
}
