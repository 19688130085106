import React, { ReactElement, FC } from 'react';
import { Container, Grid, Paper } from '@mui/material';

// Imports for custom components
import { ChildrenComponent } from '../../../interfaces/childrenComponent';
import './dashboardLayout.css';
import Sidebar from '../../navbars/sidebar/Sidebar';
import TopNavbar from '../../navbars/TopNavbar/TopNavbar';

export default function DashboardLayout(
  props: ChildrenComponent
): ReactElement<FC> {
  // Destructure props
  const { children } = props;

  return (
    <Container
      sx={{ backgroundColor: 'grey.100' }}
      className="dashboardLayoutWrap"
      disableGutters
      maxWidth={false}
    >
      <Grid container>
        <Grid item lg={2}>
          <Sidebar />
        </Grid>
        <Grid item lg={10}>
          <TopNavbar />
          <Grid padding={4} spacing={4} container>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
