import React, { FC, ReactElement } from 'react';
import { Typography, IconButton, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

interface HelperTextFields {
  helperText: string;
  tootlTipText?: string;
}

export default function HelperTextFields(
  props: HelperTextFields
): ReactElement<FC> {
  // Destructure props
  const { helperText, tootlTipText = '' } = props;

  return (
    <Typography variant="caption" component="small">
      {helperText}
      {tootlTipText.length > 0 ? (
        <Tooltip title={tootlTipText}>
          <IconButton size="small">
            <HelpOutlineOutlinedIcon />
          </IconButton>
        </Tooltip>
      ) : (
        false
      )}
    </Typography>
  );
}

HelperTextFields.propTypes = {
  helperText: PropTypes.string.isRequired,
  tootlTipText: PropTypes.string,
};
